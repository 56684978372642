/* eslint-disable */
/* stylelint-disable */

@import 'styles/variable';
@import '/styles/mixins';

.notDisplay {
  display: none !important;
}

.sgModal {
  padding: 16px 12px;

  @include sm {
    bottom: 25%;
    width: calc(100% - 72px);
    padding: 14px 0;
    margin-right: 10px;
  }

  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    color: #121212;
  }

  p {
    margin-top: 12px;
    margin-bottom: 28px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-5121212, #121212);

    /* 150% */
  }

  .items {
    display: flex;
    flex-direction: column;
    margin-left: -10px;

    .item {
      display: flex;
      gap: 13px;
      align-items: center;
      align-self: stretch;
      height: 59px;
      padding: 20px 0;
      border-bottom: 1px solid var(--line-3-e-4-e-4-e-4, #e4e4e4);

      .selection {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--main-color-black-black-01121212, #121212);
        cursor: pointer;
      }
    }
  }

  .bottomButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 28px;

    .submit {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      width: 132px;
      height: 40px;
      padding: 10px 24px;
      color: #fff;
      background: var(--main-color-blue-blue-012378-e-5, #2378e5);
      border-radius: 4px;
    }

    .cancel {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      width: 132px;
      height: 40px;
      padding: 10px 24px;
      margin-right: 10px;
      border: 1px solid var(--main-color-black-black-06-e-4-e-4-e-4, #e4e4e4);
      border-radius: 4px;
    }
  }
}

.sgNotMatchModal {
  padding: 16px 12px;
  text-align: center;

  @include sm {
    bottom: 25%;
    width: calc(100% - 72px);
    padding: 14px 0;
    margin-right: 10px;
  }

  .topback {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    height: 119px;
    background: #f9fbfc;

    .image {
      position: relative;
      right: 25px;
    }
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 28px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-5121212, #121212);

    /* 150% */
  }

  .bottomButtons {
    display: flex;
    align-items: center;
    justify-content: center;

    .cancel {
      display: flex;
      gap: 4px;
      align-items: center;
      align-self: center;
      justify-content: center;
      height: 40px;
      padding: 10px 80px;
      color: white;
      background: #2378e5;
      border-radius: 4px;
    }
  }
}

.modalSwitch {
  :global {
    .ant-modal {
      .ant-modal-content {
        padding: 32px 32px 24px;

        .modalSwitchHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          .modalSwitchHeaderTitle {
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            color: #000;
          }

          .modalSwitchHeaderTitle + span {
            cursor: pointer;
          }
        }

        .modalSwitchContent {
          color: #3b3c3c;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }

        .ant-modal-footer {
          > .ant-btn + .ant-btn {
            margin-inline-start: 12px;
          }

          .ant-btn-default {
            height: 40px;
            padding: 12px 32px;
            gap: 8px;

            border-radius: 10px;
            border: 1.2px solid #ebeef0;
            background: #fff;

            box-shadow: 0px 1px 3px 0px rgba(195, 199, 203, 0.1);

            color: #000;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 137.5% */
          }

          .ant-btn-primary {
            height: 40px;
            padding: 12px 32px;
            gap: 6px;
            border-radius: 10px;
            background: #1c70ee;

            color: #fff;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }
    }
  }
}
