/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-max-class */
/* stylelint-disable rule-empty-line-before */
.selectGlobalPopupClassName {
  &.ant-select-dropdown {
    .rc-virtual-list {
      .ant-select-item {
        padding: 7px 8px;
        color: #111212;
        border-radius: 8px;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled),
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: #f8f9fa !important;
      }
    }
  }
}

.initWrapModal {
  .ant-modal {
    max-width: 90%;

    .ant-modal-content {
      padding: 32px;
      border-radius: 16px;
    }

    .ant-upload-wrapper .ant-upload-drag {
      background: #f5f9ff;
      border: 1px dashed rgba(0, 102, 255, 0.5);
      border-radius: 16px;
    }

    .ant-progress .ant-progress-inner {
      background-color: #f4f5f6;
    }

    .ant-modal-close:hover {
      background-color: transparent;
    }
  }
}

// Global Jobseeker Completed Select
.yoloJobseekerCompletedSelect {
  .ant-select-selector {
    height: 42px !important;
    border-width: 1.2px !important;
  }

  .custom-input-container .ant-select-selector {
    background: #fff;
    border-radius: 12px !important;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  .ant-select-single .ant-select-selector {
    font-size: 16px !important;
  }

  .ant-picker .ant-picker-input > input {
    font-size: 16px;
  }

  .ant-select-focused.ant-select-outlined .ant-select-selector {
    box-shadow: none !important;
  }

  .custom-input-container .ant-select-single .ant-select-selector {
    border-color: #ebeef0;
  }

  .custom-input-container .ant-cascader {
    width: 100%;
    height: 42px;
  }

  .ant-select .ant-select-arrow {
    opacity: 1;
  }

  .ant-select.ant-select-outlined .ant-select-selector {
    background: #fff;
    border-color: #ebeef0 !important;
    border-radius: 12px !important;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  .custom-input-container .ant-select .ant-select-arrow {
    right: 10px;
  }

  .custom-input-container .ant-cascader:hover .ant-select-selector {
    border-color: #4096ff !important;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #121212;
  }

  .ant-select.ant-select-outlined:hover .ant-select-selector {
    border-color: #4096ff !important;
  }

  .ant-select-selector .ant-select-selection-placeholder {
    font-weight: 300;
    color: #646566 !important;
  }

  .ant-select-disabled .ant-select-selector .ant-select-selection-placeholder {
    opacity: 0.3;
  }

  .ant-select .ant-select-clear {
    width: 16px !important;
  }

  .yolo-custom-radio-label {
    font-size: 16px;
  }

  .yolo-custom-radio-checked {
    color: #1c70ee;
  }

  .ant-picker {
    border-color: #ebeef0;
    border-radius: 12px;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }
  .ant-picker-input input::placeholder {
    font-weight: 300 !important;
    color: #646566 !important;
  }

  .ant-input {
    font-size: 16px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  .ant-input::placeholder {
    font-weight: 300;
    color: #646566;
  }
}
